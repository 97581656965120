import {
    UseQueryOptions,
    SetDataOptions,
    useQueryClient,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { IPersonalStore } from 'interfaces'
import {
    TRequestError,
    TPersonalStoreProps,
    TPersonalStoreResponse,
    TAddPersonalStoreProps,
    TAddPersonalStoreResponse,
    TUpdatePersonalStoreProps,
    TUpdatePersonalStoreResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TPersonalStoreProps
export type TQueryFnOpts = UseQueryOptions<
    TPersonalStoreResponse,
    [string, TRequestError<TFetchFnParams>],
    TPersonalStoreResponse,
    [string, TFetchFnParams]
>

export type TAddPersonalStoreMutationFnError = [string, TRequestError<TAddPersonalStoreProps>]
export type TUpdatePersonalStoreMutationFnError = [string, TRequestError<TUpdatePersonalStoreProps>]

const key = QueryCacheKeys.personalStore

/**
 * Хук API получить магазин компании
 */
export default function useFetchPersonalStore(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchPersonalStore(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

/**
 * Mutation personal store
 * after mutation do update query cached data personal stores
 */
export function useMutationPersonalStore() {
    const { t } = useTranslation()

    const add = useMutation<
        TAddPersonalStoreResponse,
        TAddPersonalStoreMutationFnError,
        TAddPersonalStoreProps
    >((props) => {
        return MarketService.addPersonalStore(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const update = useMutation<
        TUpdatePersonalStoreResponse,
        TUpdatePersonalStoreMutationFnError,
        TUpdatePersonalStoreProps
    >((props) => {
        return MarketService.updatePersonalStore(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        add,
        update,
    }
}

/**
 * Operations with query cached data personal store
 */
export function useQueryDataPersonalStore(options?: SetDataOptions) {
    const queryClient = useQueryClient()
    const user = useAppSelector((state) => state.user)

    /**
     * Update item in cache
     */
    const update = (params: TFetchFnParams, data: IPersonalStore) => {
        return queryClient.setQueryData<TPersonalStoreResponse>([`${key}-${user.id}`, params], (queryData) => {
            return queryData ? { ...queryData, ...data } : data
        }, options)
    }

    return {
        update,
    }
}
