import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TCurrenciesProps,
    TCurrenciesResponse,
} from 'services/DirectoryService'
import { QueryCacheKeys } from 'enums'
import { DirectoryService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TCurrenciesProps
export type TQueryFnOpts = UseQueryOptions<
    TCurrenciesResponse,
    TRequestError<TFetchFnParams>,
    TCurrenciesResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.currencies

/**
 * Hook API currency list
 */
export default function useFetchCurrencies(params: TFetchFnParams = {}, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()

    return useQuery([key, params], () => {
        return DirectoryService.fetchCurrencies(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
