import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ITransaction, IPersonalStoreTransaction } from 'interfaces'
import {
    getId,
    getDateValues,
    defaultDateFormat,
    dateTimeFormat,
} from 'utils/helpers'
import { WalletTransaction } from '../index'
import style from './WalletTransactions.module.css'

type WalletTransactionsPropType = {
    classes?: string
    data?: ITransaction[] | IPersonalStoreTransaction[]
}

type TTransactionGroup = {
    date: string
    dateFormatted: string
    items: ITransaction[] | IPersonalStoreTransaction[]
}

const DATE_OPTIONS_CURRENT_YEAR: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' }
const DATE_OPTIONS_PREV_YEAR: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }

const WalletTransactions: React.FC<WalletTransactionsPropType> = ({
    classes,
    data,
}) => {
    const { t } = useTranslation()

    const [{
        year: todayYear,
        month: todayMonth,
        day: todayDay,
    }] = useState(getDateValues(new Date()))

    const [{
        year: yesterdayYear,
        month: yesterdayMonth,
        day: yesterdayDay,
    }] = useState(getDateValues(new Date(todayYear, todayMonth, todayDay - 1)))

    /** Transactions grouped by date */
    const transactionGroups = useMemo(() => {
        if (data) {
            return (data as ITransaction[]).reduce((acc, item) => {
                const dateKey = defaultDateFormat(item.date)
                const groupIndex = acc.findIndex((group) => group.date === dateKey)

                if (groupIndex >= 0) {
                    // @ts-ignore TODO
                    acc[groupIndex].items.push(item)
                    return acc
                }

                return [
                    ...acc,
                    {
                        date: dateKey,
                        dateFormatted: getGroupFormattedDate(item.date),
                        items: [item],
                    },
                ]
            }, [] as TTransactionGroup[])
        }

        return []
    }, [data])

    function getGroupFormattedDate(date: string) {
        const { year, month, day } = getDateValues(new Date(date))

        if (year === todayYear && month === todayMonth && day === todayDay) {
            return t('today')
        }
        if (year === yesterdayYear && month === yesterdayMonth && day === yesterdayDay) {
            return t('yesterday')
        }

        return dateTimeFormat(
            date,
            undefined,
            year === todayYear ? DATE_OPTIONS_CURRENT_YEAR : DATE_OPTIONS_PREV_YEAR,
            { sliceRusYearStr: true },
        )
    }

    return (
        <div className={classes}>
            {transactionGroups.map((group) => (
                <div className={style.group} key={group.date}>
                    <div className={style.date}>
                        {group.dateFormatted}
                    </div>
                    {group.items.map((item) => (
                        <WalletTransaction
                            classes={style.transaction}
                            data={{
                                orderId: item.order_id,
                                storeId: item.store_id,
                                storeName: item.store_name,
                                storeImage: 'img' in item ? item.img : '',
                                amount: typeof item.amount === 'string' ? Number(item.amount) : item.amount,
                                currency: item.currency_object,
                                typeName: item.type_name,
                            }}
                            key={'object_id' in item ? item.object_id : getId(true)}
                        />
                    ))}
                </div>
            ))}
        </div>
    )
}

export default WalletTransactions
