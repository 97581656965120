import React from 'react'

import { IPayout, ICurrency } from 'interfaces'
import { WalletItem } from '../index'

type WalletItemUserPropType = {
    classes?: string
    data: IPayout
    convertedCurrency?: Partial<ICurrency>
    index: number
    onClick: (value: IPayout) => void
}

/**
 * Wallet item user
 */
const WalletItemUser: React.FC<WalletItemUserPropType> = ({
    classes,
    data,
    convertedCurrency,
    index,
    onClick,
}) => {
    return (
        <WalletItem
            classes={classes}
            data={{
                amount: data.convertedBalanceAmount ?? 0,
                currencyCode: convertedCurrency?.showCode ?? '',
                roundscale: convertedCurrency?.roundScale ?? 2,
                isLeftCurrencySymbolPlacement: convertedCurrency?.left_currency_symbol_placement,
                backgroundImage: data.currencyIcon,
                backgroundColor: data.currencyPlaceholderColor,
            }}
            index={index}
            onClick={() => onClick(data)}
        />
    )
}

export default WalletItemUser
