import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPersonalStoreBalance } from 'interfaces'
import { Block } from 'layout'
import { Button } from 'components'
import { getCountFractionDigits, priceFormat } from 'utils/helpers'
import style from './WalletStoreInfo.module.css'

type WalletStoreInfoPropType = {
    data?: IPersonalStoreBalance
    onClickPayment: () => void
}

const WalletStoreInfo: React.FC<WalletStoreInfoPropType> = ({ data, onClickPayment }) => {
    const { t } = useTranslation()

    const amount = useMemo(() => {
        const [balance] = data?.balances || []
        const defaultFractionDigits = 2

        if (balance) {
            const fractionDigits = getCountFractionDigits(balance.roundscale, defaultFractionDigits)

            return priceFormat(balance.balanceAmount, {
                symbol: balance.currencyShowCode,
                isoCode: balance.currencyIsoCode,
                leftSymbolPlacement: false,
            }, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            })
        }

        return priceFormat(0, {
            symbol: '',
            isoCode: '',
            leftSymbolPlacement: false,
        }, {
            minimumFractionDigits: defaultFractionDigits,
            maximumFractionDigits: defaultFractionDigits,
        })
    }, [data])

    return (
        <Block classes={style.block}>
            <Block.Header classes={style.header}>
                <div className={style.amount}>
                    {amount}
                </div>
                <div className={style.name}>
                    {data?.name}
                </div>
            </Block.Header>
            <div className={style.desc}>
                {t('balance')}
            </div>
            <div className={style.actions}>
                <Button
                    disabled
                    classes={cn(style.action, style.action_btn)}
                    styleType="bordered"
                    size="size44"
                    text={t('wallet_refilling')}
                    onClick={() => onClickPayment()}
                />
            </div>
        </Block>
    )
}

export default WalletStoreInfo
