import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Image } from 'components'
import { getCountFractionDigits, priceFormat } from 'utils/helpers'
import style from './WalletItem.module.css'

type WalletItemPropType = {
    classes?: string
    data: {
        amount: number
        currencyCode: string
        roundscale: number
        backgroundColor: string
        backgroundImage: string
        name?: string
        isLeftCurrencySymbolPlacement?: boolean
    }
    index: number
    onClick: (value: any) => void
}

/**
 * Wallet common item
 */
const WalletItem: React.FC<WalletItemPropType> = ({
    classes,
    data,
    index,
    onClick,
}) => {
    const { t } = useTranslation()

    const amountSum = useMemo(() => {
        const {
            amount,
            roundscale,
            currencyCode,
            isLeftCurrencySymbolPlacement = false,
        } = data
        const fractionDigits = getCountFractionDigits(roundscale)

        return priceFormat(amount, {
            symbol: currencyCode,
            isoCode: '',
            leftSymbolPlacement: isLeftCurrencySymbolPlacement,
        }, {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        })
    }, [data])

    const handlerClick = () => {
        onClick(data)
    }

    return (
        <div
            className={cn(style.item, classes)}
            style={{ backgroundColor: data.backgroundColor }}
            role="button"
            tabIndex={index}
            onClick={handlerClick}
            onKeyDown={handlerClick}
        >
            <Image
                classesWrap={style.background}
                src={data.backgroundImage}
                isPlaceholder={false}
                width={400}
                height={267}
                resize={{ width: 400, quality: 90 }}
            />

            <div className={style.body}>
                {data.name && (
                    <div className={style.name}>
                        {data.name}
                    </div>
                )}
                <div className={style.balance}>
                    <div className={style.amount}>
                        {amountSum}
                    </div>
                </div>
                <div className={style.desc}>
                    {t('balance')}
                </div>
            </div>
        </div>
    )
}

export default WalletItem
