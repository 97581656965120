import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICurrencyData } from 'interfaces'
import { APP_URL } from 'config/app'
import { Avatar, Link } from 'components'
import { priceFormat } from 'utils/helpers'
import style from './WalletTransaction.module.css'

type WalletTransactionPropType = {
    classes?: string
    data: {
        orderId?: number
        storeId: number
        storeName: string
        storeImage: string
        amount: number
        currency: ICurrencyData
        typeName: string
    }
}

const WalletTransaction: React.FC<WalletTransactionPropType> = ({ classes, data }) => {
    const { t } = useTranslation()

    const storeUrl = useMemo(() => {
        return data ? generatePath(APP_URL.store, { id: data.storeId }) : ''
    }, [data])

    const orderUrl = useMemo(() => {
        return data?.orderId ? generatePath(APP_URL.companyOrder, { storeId: data.storeId, id: data.orderId }) : ''
    }, [data])

    const orderDesc = useMemo(() => {
        return data?.orderId ? t('business_kicks_history_order').replace('%s', String(data.orderId)) : ''
    }, [data])

    const amountSum = useMemo(() => {
        const { amount, currency: { show_code, left_currency_symbol_placement } } = data
        const currency = { symbol: show_code, leftSymbolPlacement: left_currency_symbol_placement, isoCode: '' }
        const options: Intl.NumberFormatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2, signDisplay: 'always' }

        return priceFormat(amount, currency, options)
    }, [data])

    return (
        <div className={cn(style.transaction, classes)}>
            <Link className={style.link} url={storeUrl}>
                <Avatar
                    classes={style.avatar}
                    name={data.storeName}
                    src={data.storeImage}
                    width={55}
                    height={55}
                />
            </Link>
            <div className={style.body}>
                <div className={style.title}>
                    {data.storeName}
                </div>
                {orderDesc && (
                    <div className={style.order}>
                        <Link className={style.orderLink} url={orderUrl}>
                            {orderDesc}
                        </Link>
                    </div>
                )}
            </div>
            <div className={style.info}>
                <div className={style.amount}>
                    {amountSum}
                </div>
                <div className={style.status}>
                    {data.typeName}
                </div>
            </div>
        </div>
    )
}

export default WalletTransaction
