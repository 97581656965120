import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb, IPersonalStore } from 'interfaces'
import { TUpdatePersonalStoreProps } from 'services/MarketService'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, Block } from 'layout'
import { useQueriesDataStoreProfile } from 'containers/Store/hooks'
import { useFetchPersonalStore, useQueryDataPersonalStore, useQueriesDataPersonalStores } from 'containers/Market/hooks'
import { useFetchCurrencies } from 'containers/App/hooks'
import {
    PageHeader,
    Breadcrumbs,
    Loader,
    ErrorMsg,
} from 'components'
import { StoreAction } from 'form-actions'
import { useWindowResize } from 'hooks'
import { StoreDto } from 'dto'
import { scrollTop, showAlertNotify } from 'utils/helpers'
import style from './StoreEdit.module.css'

const StoreEdit: React.FC = () => {
    const { id: storeId } = useParams<{ id: string }>()
    const history = useHistory()
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    const [storeParams, setStoreParams] = useState<TUpdatePersonalStoreProps>()

    const {
        isInitialLoading: isLoading,
        data: dataPersonalStore,
    } = useFetchPersonalStore({ id: Number(storeId) }, { enabled: !Number.isNaN(Number(storeId)) })

    const { data: dataCurrencies } = useFetchCurrencies()

    const { setQueryData: setQueryDataStoreProfile } = useQueriesDataStoreProfile()

    const { update: updatePersonalStore } = useQueryDataPersonalStore()

    const { update: updatePersonalStores } = useQueriesDataPersonalStores()

    const isMobile = useMemo(() => {
        return windowWidth < BREAKPOINTS.tabletLandscape
    }, [windowWidth])

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        const parent = { id: 1, name: t('stores'), url: APP_URL.stores }
        const shop = dataPersonalStore
            ? { id: 2, name: dataPersonalStore.name, url: generatePath(APP_URL.shop, { id: dataPersonalStore.id }) }
            : undefined
        const storeEdit = { id: 3, name: t('edit_store') }

        if (isMobile && shop) {
            return [shop, storeEdit]
        }

        if (shop) {
            return [parent, shop, storeEdit]
        }

        return []
    }, [isMobile, dataPersonalStore])

    const handlerClickBack = () => {
        history.push(generatePath(APP_URL.shop, { id: storeId }))
    }

    const handlerSuccessUpdatePersonalStore = (value: IPersonalStore) => {
        setQueryDataStoreProfile({ id: Number(storeId) }, (queryData) => {
            return queryData ? { ...queryData, ...value } : undefined
        })
        updatePersonalStore({ id: value.id }, value)
        updatePersonalStores(value)
        history.push(generatePath(APP_URL.shop, { id: value.id }))
    }

    const handlerErrorUpdatePersonalStore = (err?: string) => {
        showAlertNotify({ type: 'error', message: err && t('update_error') })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataPersonalStore && dataCurrencies) {
            const currency = dataCurrencies.find((item) => item.iso === dataPersonalStore.currency_iso_code)
            setStoreParams(StoreDto.getPersonalStoreProps(dataPersonalStore, currency ? currency.id : 0))
        }
    }, [dataPersonalStore, dataCurrencies])

    return (
        <ContentContainer classes={style.content} size="half">
            <PageHeader onClickBack={handlerClickBack}>
                <Breadcrumbs items={breadcrumbs} />
            </PageHeader>
            <Block classes={style.block}>
                {isLoading && (
                    <Loader />
                )}

                {storeParams?.isClosed && (
                    <ErrorMsg error={t('store_closed')} />
                )}

                {storeParams && !storeParams.isClosed && (
                    <StoreAction
                        storeProps={storeParams}
                        onSuccess={handlerSuccessUpdatePersonalStore}
                        onError={handlerErrorUpdatePersonalStore}
                    />
                )}
            </Block>
        </ContentContainer>
    )
}

export default StoreEdit
