import React from 'react'

import {
    InfoBlock,
    Popover,
} from 'components'
import style from './WalletMainNav.module.css'

type WalletNavPropType = {
    onOpen: () => void
}

const WalletMainNav: React.FC<WalletNavPropType> = ({
    children,
    onOpen,
}) => {
    return (
        <InfoBlock classes={style.block}>
            <Popover
                isOpen={false}
                isAutoClose={false}
                classes={style.currencyDropDown}
                position="bottom"
                side="left"
                trigger={<Popover.TriggerDots position="vertical" />}
                onOpen={onOpen}
            >
                {/**/}
            </Popover>

            <InfoBlock.Body classes={style.body}>
                {children}
            </InfoBlock.Body>
        </InfoBlock>
    )
}

export default WalletMainNav
