import React, { useState } from 'react'
import { Collapse } from 'react-collapse'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { TPayoutHistoryProps } from 'services/PayoutService'
import { DatePeriod } from 'enums'
import { Button, SvgResource } from 'components'
import { defaultDateFormat } from 'utils/helpers'
import style from './WalletTransactionsFilter.module.css'
import { WalletFilterField } from '../index'

export enum FieldName {
    from = 'dateFrom',
    to = 'dateTo',
}

export type TWalletFilterProps = Pick<TPayoutHistoryProps, 'dateFrom' | 'dateTo'>

export type WalletFilterPropType = {
    classes?: string
    onChange: (value: TWalletFilterProps) => void
}

const FILTER_PERIODS = [
    { key: DatePeriod.week, translateKey: 'calendar_period_week' },
    { key: DatePeriod.month, translateKey: 'calendar_period_month' },
    { key: DatePeriod.year, translateKey: 'calendar_period_year' },
    { key: DatePeriod.all, translateKey: 'calendar_period_all_time' },
]

const WalletTransactionsFilter: React.FC<WalletFilterPropType> = ({
    classes,
    onChange,
}) => {
    const { t } = useTranslation()

    const [filterProps, setFilterProps] = useState<TWalletFilterProps>({})
    const [today] = useState(new Date())
    const [activePeriod, setActivePeriod] = useState<keyof typeof DatePeriod | undefined>(DatePeriod.all)
    const [errorDate, setErrorDate] = useState<Record<FieldName, boolean|string|undefined> | {}>({})
    const [isOpen, setIsOpen] = useState(false)

    const handlerClickDate = () => {
        if (isOpen) {
            setFilterProps({})
        }

        setIsOpen((prevSate) => !prevSate)
    }

    const handlerChangeField = (name: FieldName, value?: string) => {
        if (filterProps[name] !== value) {
            const isValidDate = validateDatePeriod(name, value)

            if (isValidDate) {
                setErrorDate({})
                setActivePeriod(undefined)
                setFilterProps((prevState) => ({ ...prevState, [name]: value }))
                onChange({ ...filterProps, [name]: value })
            } else {
                setErrorDate({ [name]: true })
            }
        }
    }

    const handlerChangePeriod = (key: keyof typeof DatePeriod) => {
        setIsOpen(false)

        switch (key) {
            case 'week': {
                const dateFrom = defaultDateFormat(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7))
                const dateTo = defaultDateFormat(today)

                setActivePeriod(key)
                setFilterProps((prevState) => ({ ...prevState, dateFrom, dateTo }))
                onChange({ ...filterProps, dateFrom, dateTo })

                break
            }
            case 'month': {
                const dateFrom = defaultDateFormat(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()))
                const dateTo = defaultDateFormat(today)

                setActivePeriod(key)
                setFilterProps((prevState) => ({ ...prevState, dateFrom, dateTo }))
                onChange({ ...filterProps, dateFrom, dateTo })

                break
            }
            case 'year': {
                const dateFrom = defaultDateFormat(new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()))
                const dateTo = defaultDateFormat(today)

                setActivePeriod(key)
                setFilterProps((prevState) => ({ ...prevState, dateFrom, dateTo }))
                onChange({ ...filterProps, dateFrom, dateTo })

                break
            }
            case 'all': {
                setActivePeriod(key)
                setFilterProps({})
                onChange({})

                break
            }
            default:
                setFilterProps({})
                onChange({})
        }
    }

    /**
     * Validate filter date period start and end
     */
    function validateDatePeriod(name: FieldName, value?: string) {
        switch (name) {
            case FieldName.from:
                if (value && filterProps[FieldName.to]) {
                    const dateFrom = new Date(value)
                    const dateTo = new Date(filterProps[FieldName.to])

                    /** error: start date after the end date */
                    if (dateFrom > dateTo) {
                        return false
                    }
                }

                return true
            case FieldName.to:
                if (value && filterProps[FieldName.from]) {
                    const dateFrom = new Date(filterProps[FieldName.from])
                    const dateTo = new Date(value)

                    /** error: end date before the start date */
                    if (dateFrom > dateTo) {
                        return false
                    }
                }

                return true
            default:
                return false
        }
    }

    return (
        <div className={cn(style.filter, classes)}>
            <div className={style.actions}>
                {FILTER_PERIODS.map((item) => (
                    <Button
                        textUpper={false}
                        classes={cn(
                            style.action,
                            style.action_btn,
                            { [style.action_btn_active]: item.key === activePeriod },
                        )}
                        styleType="bordered"
                        size="size32"
                        text={t(item.translateKey)}
                        key={item.key}
                        onClick={() => (item.key !== activePeriod ? handlerChangePeriod(item.key) : undefined)}
                    />
                ))}

                <Button
                    classes={style.action}
                    styleType="text"
                    onClick={handlerClickDate}
                >
                    <SvgResource
                        isImgTag={false}
                        classes={style.iconCalendar}
                        resourceKey="wallet_icon_calendar_svg"
                        width={20}
                        height={20}
                    />
                </Button>
            </div>
            <Collapse isOpened={isOpen}>
                <div className={style.fields}>
                    <WalletFilterField
                        classes={style.field}
                        placeholder={t('ui_posts_spinner_date_from_hint')}
                        dateValue={isOpen ? filterProps[FieldName.from] : undefined}
                        error={errorDate[FieldName.from]}
                        onChange={(value) => handlerChangeField(FieldName.from, value)}
                    />
                    <WalletFilterField
                        classes={style.field}
                        placeholder={t('ui_posts_spinner_date_to_hint')}
                        dateValue={isOpen ? filterProps[FieldName.to] : undefined}
                        error={errorDate[FieldName.to]}
                        onChange={(value) => handlerChangeField(FieldName.to, value)}
                    />
                </div>
            </Collapse>
        </div>
    )
}

export default WalletTransactionsFilter
