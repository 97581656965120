import React, { useMemo } from 'react'

import { IPersonalStoreBalance, ICurrency } from 'interfaces'
import { WalletItem } from '../index'

type WalletPersonalStorePropType = {
    classes?: string
    data: IPersonalStoreBalance
    convertedCurrency?: Partial<ICurrency>
    onClick: (value: IPersonalStoreBalance) => void
}

/**
 * Wallet item personal store
 */
const WalletItemPersonalStore: React.FC<WalletPersonalStorePropType> = ({
    classes,
    data,
    convertedCurrency,
    onClick,
}) => {
    const walletData = useMemo(() => {
        const [balance] = data.balances || []

        return {
            name: data.name,
            amount: data.convertedBalanceAmount ?? 0,
            currencyCode: convertedCurrency?.showCode ?? '',
            roundscale: convertedCurrency?.roundScale ?? 2,
            isLeftCurrencySymbolPlacement: convertedCurrency?.left_currency_symbol_placement,
            backgroundImage: balance.currencyIcon,
            backgroundColor: balance.currencyPlaceholderColor,
        }
    }, [data])

    return (
        <WalletItem
            classes={classes}
            data={walletData}
            index={0}
            onClick={() => onClick(data)}
        />
    )
}

export default WalletItemPersonalStore
