import {
    UseQueryOptions,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TPersonalStoresBalanceProps,
    TPersonalStoresBalanceResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TPersonalStoresBalanceProps
export type TQueryFnOpts = UseQueryOptions<
    TPersonalStoresBalanceResponse,
    TRequestError<TFetchFnParams>,
    TPersonalStoresBalanceResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.personalStoresBalances

/**
 * Hook API personal stores balance of wallets
 */
export default function useFetchPersonalStoresBalance(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchPersonalStoresBalance(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
