import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb, IPersonalStore } from 'interfaces'
import { APP_URL } from 'config/app'
import { ContentContainer, Block } from 'layout'
import * as companySelectors from 'containers/Company/company-selectors'
import { useQueriesDataPersonalStores } from 'containers/Market/hooks'
import { PageTitle, Breadcrumbs } from 'components'
import { StoreAction } from 'form-actions'
import { scrollTop, showAlertNotify } from 'utils/helpers'
import style from './StoreCreate.module.css'

const StoreCreate: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const company = useSelector(companySelectors.userCompany)

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        return [
            { id: 1, name: t('stores'), url: APP_URL.stores },
            { id: 2, name: t('add_store') },
        ]
    }, [])

    const { add: addPersonalStores } = useQueriesDataPersonalStores()

    const handlerSuccessCreatePersonalStore = (value: IPersonalStore) => {
        addPersonalStores(value)
        history.push(generatePath(APP_URL.storeSettings, { id: value.id }))
    }

    const handlerErrorCreatePersonalStore = (err?: string) => {
        showAlertNotify({ type: 'error', message: err && t('update_error') })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <ContentContainer classes={style.content} size="half">
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>
            <Block classes={style.block}>
                <StoreAction
                    storeProps={{
                        companyId: company.id,
                    }}
                    onSuccess={handlerSuccessCreatePersonalStore}
                    onError={handlerErrorCreatePersonalStore}
                />
            </Block>
        </ContentContainer>
    )
}

export default StoreCreate
