import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { API_HOST } from 'config/api'
import { ContentContainer, Block } from 'layout'
import {
    PageHeader,
    CustomSelect,
    Image,
    Modal,
} from 'components'
import { fetchUser } from 'containers/User/user-actions'
import { fetchCompanyUser } from 'containers/Company/company-actions'
import { useQueriesDataPersonalStores } from 'containers/Market/hooks'
import * as appSelectors from 'containers/App/app-selectors'
import * as userSelectors from 'containers/User/user-selectors'
import * as companySelectors from 'containers/Company/company-selectors'
import { CompanyAction } from 'form-actions'
import { CompanyService, UserService } from 'services'
import { useThunkDispatch } from 'hooks'
import { scrollTop, showAlertNotify, getRequestError } from 'utils/helpers'

import styleCommon from 'styles/modules/common.module.css'
import style from './Company.module.css'

const Company: React.FC = () => {
    const { t } = useTranslation()
    const thunkDispatch = useThunkDispatch()

    const { companyIds, forbidden: forbiddenConfig } = useSelector(appSelectors.config)
    const user = useSelector(userSelectors.user)
    const company = useSelector(companySelectors.userCompany)

    const [isLoading, setIsLoading] = useState(false)
    const [isShowModalCompany, setIsShowModalCompany] = useState(false)

    const accounts = useMemo(() => {
        if (user) {
            /**
             * KW-256
             * если в app конфиге указаны id компаний, можно переключаться только по ним,
             * если не указаны - по всем
             */
            return companyIds.length
                ? user.accounts.filter((item) => companyIds.includes(item.company_id))
                : user.accounts
        }
        return []
    }, [user, companyIds])

    const { invalidate: invalidatePersonalStores } = useQueriesDataPersonalStores()

    const handlerChangeCompanyAccount = (accountId: number) => {
        changeAccountAction(accountId)
    }

    const handlerAddCompany = () => {
        setIsShowModalCompany(true)
    }

    function changeAccountAction(id: number) {
        setIsLoading(true)
        UserService.changeAccount(id)
            .then(() => {
                return fetchUserAction()
            })
            .then(() => {
                return fetchCompanyUserAction()
            })
            .then((data) => {
                if (CompanyService.isCanEditCompany(data?.company_role.id)) {
                    setIsLoading(false)
                    invalidatePersonalStores()
                } else {
                    showAlertNotify({ type: 'error', message: t('No access') })
                }
            })
            .catch((err) => {
                const errorText = getRequestError(err)
                setIsLoading(false)
                showAlertNotify({ type: 'error', message: errorText || t('update_error') })
            })
    }

    function fetchUserAction() {
        return thunkDispatch(fetchUser())
    }

    function fetchCompanyUserAction() {
        return thunkDispatch(fetchCompanyUser())
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <>
            <ContentContainer classes={cn(style.content, { [styleCommon.updating]: isLoading })} size="half">
                <PageHeader classes={style.header} title={t('edit')}>
                    {!!accounts.length && (
                        <CustomSelect.Select classes={cn(style.headerAction, style.actionSelect)}>
                            <CustomSelect.Field title={company.juridical_name}>
                                {company.juridical_name}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {accounts.map((item) => (
                                    <CustomSelect.Option
                                        classes={cn({ [style.selectItemActive]: item.company_id === company.id })}
                                        id={item.id}
                                        key={item.id}
                                        onClick={handlerChangeCompanyAccount}
                                    >
                                        <Image
                                            classesWrap={style.selectItemIconWrap}
                                            src={`${API_HOST}${item.company_logo}`}
                                            isPlaceholder={false}
                                            width={40}
                                            height={40}
                                            resize={{ quality: 90, width: 40, height: 40 }}
                                        />
                                        <div className={style.selectItemText}>
                                            {item.company_name}
                                        </div>
                                    </CustomSelect.Option>
                                ))}
                                {forbiddenConfig.companyAdd !== false && (
                                    <CustomSelect.Option id={0} onClick={handlerAddCompany}>
                                        {t('add_company')}
                                    </CustomSelect.Option>
                                )}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                </PageHeader>
                <Block classes={style.block}>
                    <CompanyAction
                        company={company}
                    />
                </Block>
            </ContentContainer>

            <Modal
                isOpen={isShowModalCompany}
                size="medium"
                onClose={() => setIsShowModalCompany(false)}
            >
                <Modal.Header isCloseButton title={t('add_company')} />
                <Modal.Body>
                    <CompanyAction />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Company
