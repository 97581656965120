import React, { useEffect, useState } from 'react' // useContext,
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import cn from 'classnames'

import { ICurrency } from 'interfaces'
import { INPUT_DEBOUNCE_TIME } from 'config/app'
import {
    Input,
    Loader,
    MenuList,
} from 'components'
import { useFetchCurrencies } from 'containers/App/hooks'
import { numberFormat, filterArrayObjByField } from 'utils/helpers'
import style from './CurrencyFilterList.module.css'

type CurrencyFilterListPropType = {
    classes?: string
    defaultCurrencyId?: number
    onChange: (data: ICurrency) => void
}

type currencyExtended = ICurrency & { name: string }

function filterCurrencies(data: currencyExtended[], value: string): currencyExtended[] {
    return filterArrayObjByField(data, ['iso', 'name'], value)
}

function prepareCurrencies(data: ICurrency[]): currencyExtended[] {
    return data.map((item) => {
        const { iso } = item
        const formatOptions = {
            style: 'currency',
            currency: iso,
            currencyDisplay: 'name',
            minimumFractionDigits: 0, // fixes a maximumFractionDigits is out of range
            maximumFractionDigits: 0,
        }

        if (iso === 'KICKS_SESSIA') {
            return { ...item, name: iso }
        }

        return { ...item, name: numberFormat(1, formatOptions).replace('1', '') }
    })
}

const CurrencyFilterList: React.FC<CurrencyFilterListPropType> = ({
    classes,
    defaultCurrencyId,
    onChange,
}) => {
    const { t } = useTranslation()

    const [currencies, setCurrencies] = useState<currencyExtended[]>([])
    const [filteredCurrencies, setFilteredCurrencies] = useState<currencyExtended[]>([])

    const debounce = useDebouncedCallback(searchCallback, INPUT_DEBOUNCE_TIME)

    const { isLoading, data: currenciesData } = useFetchCurrencies()

    const handlerClick = (data: currencyExtended) => {
        const { name, ...currency } = data
        onChange(currency)
    }

    const handlerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        debounce(e.target.value)
    }

    function searchCallback(value: string) {
        setFilteredCurrencies(filterCurrencies(currencies, value))
    }

    useEffect(() => {
        if (currenciesData) {
            setCurrencies(prepareCurrencies(currenciesData))
        }
    }, [currenciesData])

    useEffect(() => {
        setFilteredCurrencies(currencies)
    }, [currencies])

    return (
        <>
            <Input
                placeholder={t('search')}
                onChange={handlerSearch}
            />

            {isLoading && (
                <Loader classes={style.loader} size="small" />
            )}

            {!isLoading && !!filteredCurrencies.length && (
                <MenuList classes={cn(style.list, classes)}>
                    {filteredCurrencies.map((item) => (
                        <MenuList.Item
                            classes={style.item}
                            active={item.id === defaultCurrencyId}
                            key={item.id}
                            onClick={() => handlerClick(item)}
                        >
                            <span className={style.symbol}>
                                {item.showCode}
                            </span>
                            <span className={style.desc}>
                                {item.iso}
                            </span>
                        </MenuList.Item>
                    ))}
                </MenuList>
            )}

            {!isLoading && !filteredCurrencies.length && (
                <div className={style.noResult}>
                    {t('no_data')}
                </div>
            )}
        </>
    )
}

export default CurrencyFilterList
