import { AnyAction, combineReducers } from 'redux'

import { IRootState } from 'interfaces'
import { appReducer, geolocationReducer } from 'containers/App/app-reducer'
import {
    authorizeReducer,
    userReducer,
    profileReducer,
    tagReducer,
    countReducer,
} from 'containers/User/user-reducer'
import { companyUserReducer, companyRolesReducer } from 'containers/Company/company-reducer'
import { ordersReducer } from 'containers/Market/market-reducer'
import { ticketReducer } from 'containers/Support/support-reducer'
import { CLEAR_STATE } from './types'

const reducers = combineReducers({
    app: appReducer,
    authorize: authorizeReducer,
    user: userReducer,
    profile: profileReducer,
    company: companyUserReducer,
    companyRoles: companyRolesReducer,
    geolocation: geolocationReducer,
    tags: tagReducer,
    orders: ordersReducer,
    counts: countReducer,
    tickets: ticketReducer,
})

const rootReducer = (state: IRootState, action: AnyAction) => {
    switch (action.type) {
        case CLEAR_STATE:
            return reducers(action.payload, action)

        default:
            return reducers(state, action)
    }
}

export default rootReducer
