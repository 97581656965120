import React, { useMemo, useState } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IPayout,
    IPersonalStoreBalance,
    ICurrency,
} from 'interfaces'
import { TPayoutHistoryProps } from 'services/PayoutService'
import { APP_URL, BREAKPOINTS, CLIENT_STORAGE_WALLET_CURRENCY_KEY } from 'config/app'
import {
    ContentContainer,
    SideBarContainer,
    ContentBackgroundBottom,
    Block,
} from 'layout'
import {
    PageHeader,
    CurrencyFilterList,
    Loader,
    Modal,
    NoDataInfo,
} from 'components'
import { useAppMode } from 'containers/App/hooks'
import { useFetchPersonalStoresBalance, useFetchPersonalStoresHistory } from 'containers/Market/hooks'
import { useWindowResize } from 'hooks'
import { StorageService } from 'services'
import { priceFormat, scrollTop, getCountFractionDigits } from 'utils/helpers'
import { useAppSelector } from 'store'
import {
    WalletTransactionsFilter,
    WalletTransactions,
    WalletItemTotal,
    WalletItemUser,
    WalletItemPersonalStore,
} from '../components'
import { WalletMainNav } from './components'
import { useFetchPayoutHistory, useFetchPayoutOptions } from '../hooks'
import style from './WalletMain.module.css'

const storageCurrency = StorageService.getItem<ICurrency>(CLIENT_STORAGE_WALLET_CURRENCY_KEY)

const WalletMain: React.FC = () => {
    const { t } = useTranslation()
    const { isBusinessMode } = useAppMode()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const {
        id: userCurrencyId,
        show_code: userCurrencyCode,
        left_currency_symbol_placement: userCurrencyLeftSymbol,
    } = useAppSelector((state) => {
        return state.user?.country?.default_currency
            ? state.user.country.default_currency
            : { id: 0, show_code: '', left_currency_symbol_placement: false }
    })

    const [activePayout, setActivePayout] = useState<IPayout>()
    const [transactionsFilterProps, setTransactionsFilterProps] = useState<Pick<TPayoutHistoryProps, 'dateFrom' | 'dateTo'>>()
    const [currentCurrency, setCurrentCurrency] = useState<Partial<ICurrency>>(storageCurrency || {
        id: userCurrencyId,
        showCode: userCurrencyCode,
        left_currency_symbol_placement: userCurrencyLeftSymbol,
    })
    const [isShowWalletItemTotal, setIsShowWalletItemTotal] = useState(false)
    const [isOpenModalCurrency, setIsOpenModalCurrency] = useState(false)

    const {
        isFetching: isFetchingPersonalStores,
        data: dataPersonalStoresBalance,
    } = useFetchPersonalStoresBalance({
        converted_balance_currency_id: currentCurrency.id,
    }, {
        enabled: isBusinessMode && !!currentCurrency,
        keepPreviousData: true,
    })

    const {
        isInitialLoading: isLoadingHistory,
        isFetching: isFetchingHistory,
        data: dataPersonalStoresHistory,
    } = useFetchPersonalStoresHistory({
        ...transactionsFilterProps,
    }, {
        enabled: isBusinessMode,
        keepPreviousData: true,
    })

    const {
        isFetching: isFetchingPayout,
        data: dataPayouts,
    } = useFetchPayoutOptions({
        converted_balance_currency_id: currentCurrency.id,
    }, {
        enabled: !isBusinessMode && !!currentCurrency,
        keepPreviousData: true,
    })

    const {
        isInitialLoading: isLoadingTransactions,
        isFetching: isFetchingTransactions,
        data: dataTransactions,
    } = useFetchPayoutHistory({
        ...transactionsFilterProps,
    }, {
        enabled: !isBusinessMode,
        keepPreviousData: true,
    })

    const totalSumCurrency = useMemo(() => {
        const { showCode = '', left_currency_symbol_placement: leftSymbolPlacement = false, roundScale } = currentCurrency
        const fractionDigits = getCountFractionDigits(roundScale)
        const priceCurrency = { symbol: showCode, leftSymbolPlacement, isoCode: '' }
        const priceOptions = { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
        let sum = 0

        if (dataPersonalStoresBalance) {
            sum = dataPersonalStoresBalance.reduce((acc, item) => {
                return item.convertedBalanceAmount ? acc + item.convertedBalanceAmount : acc
            }, 0)
        }
        if (dataPayouts) {
            sum = dataPayouts.reduce((acc, item) => {
                return item.convertedBalanceAmount ? acc + item.convertedBalanceAmount : acc
            }, 0)
        }

        return priceFormat(sum, priceCurrency, priceOptions)
    }, [dataPersonalStoresBalance, dataPayouts])

    const handlerChangeTransactionsFilter = (values: Pick<TPayoutHistoryProps, 'dateFrom' | 'dateTo'>) => {
        setTransactionsFilterProps(values)
    }

    const handlerChangeCurrency = (value: ICurrency) => {
        setCurrentCurrency(value)
        setIsOpenModalCurrency(false)
        StorageService.setItem(CLIENT_STORAGE_WALLET_CURRENCY_KEY, value)
    }

    const handlerClickWalletItemTotal = () => {
        setActivePayout(undefined)
        setIsShowWalletItemTotal(false)
    }

    const handlerClickWalletItemPersonalStore = (value: IPersonalStoreBalance) => {
        history.push(generatePath(APP_URL.walletStore, { id: value.id }))
    }

    const handlerClickWalletItemUser = (value: IPayout) => {
        setActivePayout(value)
        setIsShowWalletItemTotal(true)
        scrollTop()
    }

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                <PageHeader
                    title={t('wallet')}
                />

                <WalletMainNav onOpen={() => setIsOpenModalCurrency(true)}>
                    <div className={style.amountSum}>
                        {totalSumCurrency}
                    </div>
                    <div className={style.amountDesc}>
                        {t('payout_total_funds')}
                    </div>
                </WalletMainNav>

                {windowWidth < BREAKPOINTS.desktop && (
                    <div className={style.mobileBar}>
                        <div className={style.payoutsMobile}>
                            {dataPersonalStoresBalance?.map((item) => (
                                <WalletItemPersonalStore
                                    classes={cn(
                                        style.listItem,
                                        { [style.listItem_updating]: isFetchingPersonalStores },
                                    )}
                                    data={item}
                                    convertedCurrency={currentCurrency}
                                    key={item.id}
                                    onClick={handlerClickWalletItemPersonalStore}
                                />
                            ))}

                            {dataPayouts?.map((item, index) => (
                                (!activePayout || item.currencyCode !== activePayout.currencyCode) && (
                                    <WalletItemUser
                                        classes={cn(style.listItem, { [style.listItem_updating]: isFetchingPayout })}
                                        data={item}
                                        convertedCurrency={currentCurrency}
                                        index={index}
                                        key={item.currencyCode}
                                        onClick={handlerClickWalletItemUser}
                                    />
                                )
                            ))}
                        </div>
                    </div>
                )}

                <div className={style.contentInner}>
                    <ContentBackgroundBottom isWidthGutter />

                    {(isLoadingHistory || isLoadingTransactions) && (
                        <Loader />
                    )}

                    {(dataPersonalStoresHistory || dataTransactions) && (
                        <Block classes={style.block}>
                            <Block.Header>
                                <div className={style.blockTitle}>
                                    {t('wallet_total_income')}
                                </div>
                            </Block.Header>
                            <WalletTransactionsFilter
                                classes={cn(
                                    style.filter,
                                    { [style.filter_updating]: isFetchingHistory || isFetchingTransactions },
                                )}
                                onChange={handlerChangeTransactionsFilter}
                            />

                            {((dataPersonalStoresHistory && !dataPersonalStoresHistory.data.length)
                                || (dataTransactions && !dataTransactions.length)
                            ) && (
                                <NoDataInfo classes={style.noDataInfo} text={t('no_data')} />
                            )}

                            <WalletTransactions
                                classes={style.transactions}
                                data={dataTransactions || dataPersonalStoresHistory?.data}
                            />

                            {/* FIXME on ready backend
                                <Pagination
                                    classes={style.pagination}
                                    current={}
                                    total={}
                                    onChange={() => {}}
                                />
                            */}
                        </Block>
                    )}
                </div>
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    <div className={style.list}>
                        {isShowWalletItemTotal && (
                            <WalletItemTotal
                                classes={style.listItem}
                                amount={totalSumCurrency}
                                onClick={handlerClickWalletItemTotal}
                            />
                        )}

                        {dataPersonalStoresBalance?.map((item) => (
                            <WalletItemPersonalStore
                                classes={cn(style.listItem, { [style.listItem_updating]: isFetchingPersonalStores })}
                                data={item}
                                convertedCurrency={currentCurrency}
                                key={item.id}
                                onClick={handlerClickWalletItemPersonalStore}
                            />
                        ))}

                        {dataPayouts?.map((item, index) => (
                            (!activePayout || item.currencyCode !== activePayout.currencyCode) && (
                                <WalletItemUser
                                    classes={cn(style.listItem, { [style.listItem_updating]: isFetchingPayout })}
                                    data={item}
                                    convertedCurrency={currentCurrency}
                                    index={index}
                                    key={item.currencyCode}
                                    onClick={handlerClickWalletItemUser}
                                />
                            )
                        ))}
                    </div>
                </SideBarContainer>
            )}

            <Modal
                isOpen={isOpenModalCurrency}
                size="smallCenter"
                onClose={() => setIsOpenModalCurrency(false)}
            >
                <Modal.Header
                    isCloseButton
                    classes={style.modalHeader}
                    title={t('select_currency')}
                    titlePos="left"
                />
                <Modal.Body>
                    <CurrencyFilterList
                        classes={style.listCurrency}
                        defaultCurrencyId={currentCurrency.id}
                        onChange={handlerChangeCurrency}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WalletMain
