import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { EMAIL_SALES } from 'config/app'
import { Button, Input } from 'components'
import { copyTextClipboard, showAlertNotify } from 'utils/helpers'
import { useAppSelector } from 'store'
import styleForm from 'styles/modules/form.module.css'
import style from './WalletStoreTransferDetails.module.css'

const WalletStoreTransferDetails: React.FC = () => {
    const { t } = useTranslation()

    const user = useAppSelector((state) => state.user)

    const [sum, setSum] = useState<number>()

    const handlerChangeSum = ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => {
        const { value } = currentTarget

        if (Number.isInteger(Number(value))) {
            setSum(Number(value))
        }
    }

    const handlerClickShare = () => {
        copyTextClipboard(`${sum}`) // TODO text
            .then(() => {
                showAlertNotify({ type: 'success', message: t('Copied to clipboard') })
            })
            .catch(() => {
                showAlertNotify({ type: 'error', message: t('update_error') })
            })
    }

    return (
        <div className={style.wrap}>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div>
                    {t('wallet_payment_bank_transfer')}
                </div>
                <div>
                    {/* TODO */}
                    {t('wallet_order_number')}
                    :
                    1361804
                </div>
                <div>
                    {`ID: ${user.member_id}`}
                </div>
                <div>
                    {`${t('email_hint')}: `}
                    <a className={style.link} href={`mailto:${EMAIL_SALES}`}>
                        {EMAIL_SALES}
                    </a>
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20, styleForm.row_vAlignCenter)}>
                <div className={style.fieldText}>
                    {`${t('market_product_sum')}:`}
                </div>
                <Input
                    value={sum}
                    onChange={handlerChangeSum}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                {/* TODO */}
                Платежные реквизиты:
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                Для пополнения вашего Баланса в приложении Kicksback по безналичному расчету от имени
                юридического лица или ИП используйте указанные реквизиты и назначение платежа.
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div>
                    {t('warning')}
                </div>
                {/* TODO */}
                В наименовании платежа обязательно укажите: Авансовый платеж по программе лояльности
                и номер заказа.
            </div>

            <div className={cn(styleForm.controls, styleForm.controls_32)}>
                <Button
                    size="size44"
                    text={t('link_menu_share')}
                    onClick={handlerClickShare}
                />
            </div>
        </div>
    )
}

export default WalletStoreTransferDetails
