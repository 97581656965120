import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useFormState, Field } from 'react-final-form'
import cn from 'classnames'

import { IPersonalStore } from 'interfaces'
import { useFetchStoreShowcase } from 'containers/Store/hooks'
import { Button, CustomSelect, Tag } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './WarehouseSuppliesFilterForm.module.css'

export enum FieldName {
    formId = 'formId',
    store = 'stores_ids',
    goods = 'goods_ids',
}

export type FormDataType = {
    [FieldName.formId]?: string
    [FieldName.store]?: number[]
    [FieldName.goods]?: number[]
}

type WarehouseSuppliesFilterFormPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    stores?: IPersonalStore[]
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

type TMultiField = FieldName.store | FieldName.goods

export const formDataInitial: FormDataType = {
    //
}

const TAGS_MAX_SHOW = 4

const WarehouseSuppliesFilterForm: React.FC<WarehouseSuppliesFilterFormPropType> = ({
    classes,
    classesBody,
    classesFooter,
    isSubmitting,
    stores,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { change } = useForm<FormDataType>()
    const { values } = useFormState<FormDataType>()

    const [goods, setGoods] = useState<Record<number, string>>({})
    const [activeStoreId, setActiveStoreId] = useState(0)

    const {
        data: dataStoreShowcase,
    } = useFetchStoreShowcase({
        storeId: activeStoreId,
    }, {
        enabled: !!activeStoreId,
    })

    const storesData = useMemo<Record<number, string>>(() => {
        return stores
            ? stores.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [stores])

    const handlerAddFormFieldItem = (field: TMultiField, value: number) => {
        const fieldValue = values[field] ?? []

        if (!fieldValue.includes(value)) {
            change(field, [...fieldValue, value])
        }
    }

    const handlerDeleteFormFieldItem = (field: TMultiField, value: number) => {
        const fieldValue = values[field] ?? []
        const newValue = fieldValue.reduce((acc, item) => {
            return item !== value ? [...acc, item] : acc
        }, [] as typeof fieldValue)

        /** Reset active store catalog goods */
        if (field === FieldName.store) {
            setActiveStoreId(0)
        }

        change(field, newValue)
    }

    useEffect(() => {
        if (dataStoreShowcase) {
            setGoods((prevState) => {
                return dataStoreShowcase.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), prevState)
            })
        }
    }, [dataStoreShowcase])

    return (
        <form
            id={values[FieldName.formId]}
            className={cn(classes, { [styleForm.submitting]: isSubmitting })}
            onSubmit={onSubmit}
        >
            <div className={classesBody}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {t('stores')}
                    </div>
                    <Field
                        <FormDataType[FieldName.store]>
                        name={FieldName.store}
                        render={({ input }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field showArrow classes={style.fieldSelect}>
                                    {(!input.value || !input.value.length) && t('all')}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {stores?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            isActive={input.value && input.value.includes(item.id)}
                                            key={item.id}
                                            onClick={() => {
                                                setActiveStoreId(item.id)
                                                handlerAddFormFieldItem(FieldName.store, item.id)
                                            }}
                                        >
                                            {item.name}
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                    {values[FieldName.store] && (
                        <div className={style.tags}>
                            {values[FieldName.store].map((storeId, index) => (
                                index < TAGS_MAX_SHOW && (
                                    <Tag
                                        classes={style.tag}
                                        classesContent={style.tagContent}
                                        classesCross={style.tagCross}
                                        key={storeId}
                                        onClick={() => setActiveStoreId(storeId)}
                                        onDelete={() => handlerDeleteFormFieldItem(FieldName.store, storeId)}
                                    >
                                        {storesData[storeId]}
                                    </Tag>
                                )
                            ))}
                            {values[FieldName.store].length > TAGS_MAX_SHOW && (
                                <div className={style.tagsCount}>
                                    {`+${values[FieldName.store].length - TAGS_MAX_SHOW}`}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {t('order_product')}
                    </div>
                    <Field
                        <FormDataType[FieldName.goods]>
                        name={FieldName.goods}
                        render={({ input }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field showArrow classes={style.fieldSelect}>
                                    {(!input.value || !input.value.length) && t('all')}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {dataStoreShowcase?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            isActive={input.value && input.value.includes(item.id)}
                                            key={item.id}
                                            onClick={() => handlerAddFormFieldItem(FieldName.goods, item.id)}
                                        >
                                            {item.name}
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                    {values[FieldName.goods] && (
                        <div className={style.tags}>
                            {values[FieldName.goods].map((goodsId, index) => (
                                index < TAGS_MAX_SHOW && (
                                    <Tag
                                        classes={style.tag}
                                        classesContent={style.tagContent}
                                        classesCross={style.tagCross}
                                        key={goodsId}
                                        onDelete={() => handlerDeleteFormFieldItem(FieldName.goods, goodsId)}
                                    >
                                        {goods[goodsId]}
                                    </Tag>
                                )
                            ))}
                            {values[FieldName.goods].length > TAGS_MAX_SHOW && (
                                <div className={style.tagsCount}>
                                    {`+${values[FieldName.goods].length - TAGS_MAX_SHOW}`}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className={classesFooter}>
                <Button
                    classes={style.control}
                    size="size44"
                    type="submit"
                    text={t('refresh')}
                />
            </div>
        </form>
    )
}

export default WarehouseSuppliesFilterForm
