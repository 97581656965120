import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, useForm, useFormState } from 'react-final-form'
import cn from 'classnames'

import {
    ICompanyUser,
    ICountry,
    IPersonalStore,
    IDeliveryStatus,
    IPaymentStatuses,
    IPaymentMethods,
} from 'interfaces'
import { CardContainer, Block } from 'layout'
import {
    Button,
    CountryDataSelect,
    CustomSelect,
    DatePicker,
    Input,
    Tabs,
    Tag,
    Tooltip,
    SvgResource,
    Suggest,
    Switch,
    Modal,
} from 'components'
import { useFetchCompanyVendorCodes } from 'containers/Orders/hooks'
import { useFetchStoreShowcase } from 'containers/Store/hooks'
import { getId, defaultDateFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './CompanyOrdersFilterForm.module.css'

export enum FieldName {
    formId = 'formId',
    orderId = 'order_id',
    orderDateFrom = 'creation_date_from',
    orderDateTo = 'creation_date_to',
    paymentDateFrom = 'payment_date_from',
    paymentDateTo = 'payment_date_to',
    userName = 'recipient_name',
    userCode = 'recipient_code',
    userPhoneEmail = 'phone_or_email',
    country = 'country_id',
    store = 'stores_ids',
    goods = 'goods_ids',
    vendor = 'goods_vendor_codes',
    deliveryAddress = 'deliveryAddress',
    deliveryStatus = 'delivery_statuses',
    paymentStatus = 'payment_statuses',
    paymentMethods = 'payment_methods',
    isDeliveryRequired = 'delivery-only',
}

export type FormDataType = {
    [FieldName.formId]?: string
    [FieldName.orderId]?: number
    [FieldName.orderDateFrom]?: string
    [FieldName.orderDateTo]?: string
    [FieldName.paymentDateFrom]?: string
    [FieldName.paymentDateTo]?: string
    [FieldName.userName]?: string
    [FieldName.userCode]?: string
    [FieldName.userPhoneEmail]?: string
    [FieldName.country]?: number
    [FieldName.store]?: number[]
    [FieldName.goods]?: number[]
    [FieldName.vendor]?: string[]
    [FieldName.deliveryAddress]?: string
    [FieldName.deliveryStatus]?: number[]
    [FieldName.paymentStatus]?: number[]
    [FieldName.paymentMethods]?: number[]
    [FieldName.isDeliveryRequired]?: boolean
}

type TDateField = FieldName.orderDateFrom | FieldName.orderDateTo | FieldName.paymentDateFrom | FieldName.paymentDateTo

type TMultiField = FieldName.store
    | FieldName.goods
    | FieldName.vendor
    | FieldName.deliveryStatus
    | FieldName.paymentStatus
    | FieldName.paymentMethods

type CompanyOrdersFilterFormPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    isSubmitting?: boolean
    company: ICompanyUser
    countries: ICountry[]
    stores?: IPersonalStore[]
    deliveryStatuses?: IDeliveryStatus[]
    paymentStatuses?: IPaymentStatuses
    paymentMethods?: IPaymentMethods
    onError?: (error: any) => void
    onChange?: (values: FormDataType) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    //
}

const TAGS_MAX_SHOW = 4

const CompanyOrdersFilterForm: React.FC<CompanyOrdersFilterFormPropType> = ({
    classes,
    classesBody,
    classesFooter,
    isSubmitting,
    company,
    countries,
    stores,
    deliveryStatuses,
    paymentStatuses,
    paymentMethods,
    onError,
    onChange,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { change, batch } = useForm<FormDataType>()
    const { values } = useFormState<FormDataType>()

    const [activeTabDateId, setActiveTabDateId] = useState(1)
    const [activeStoreId, setActiveStoreId] = useState(0)
    const [dateCalendar] = useState<Date>(new Date())
    const [dateField, setDateField] = useState<TDateField>()
    const [dateSelected, setDateSelected] = useState<Date>()
    const [textSearchVendorCodes, setTextSearchVendorCodes] = useState('')
    /**
     *  All goods of previous selected stores
     *  { [goods id]: [goods name] }
     */
    const [goods, setGoods] = useState<Record<number, string>>({})
    /**
     *  All vendors of previous selected stores
     *  { [vendor id]: [vendor name] }
     */
    const [vendors, setVendors] = useState<Record<string, string>>({})
    const [goodsSuggestId, setGoodsSuggestId] = useState(getId(false))
    const [vendorsSuggestId, setVendorsSuggestId] = useState(getId(false))
    const [goodsSuggest, setGoodsSuggest] = useState<{ id: number, value: string }[]>([])
    const [vendorsSuggest, setVendorsSuggest] = useState<{ id: number, value: string }[]>([])
    const [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false)

    const {
        data: dataStoreShowcase,
    } = useFetchStoreShowcase({
        storeId: activeStoreId,
    }, {
        enabled: !!activeStoreId,
    })

    const {
        data: dataCompanyVendorCodes,
    } = useFetchCompanyVendorCodes({
        companyId: company.id,
        text: textSearchVendorCodes,
    }, {
        enabled: !!textSearchVendorCodes,
    })

    const countriesData = useMemo<Record<number, ICountry>>(() => {
        return countries
            ? countries.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
            : {}
    }, [countries])

    const storesData = useMemo<Record<number, IPersonalStore>>(() => {
        return stores
            ? stores.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
            : {}
    }, [stores])

    const deliveryStatusesData = useMemo(() => {
        return deliveryStatuses
            ? deliveryStatuses.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [deliveryStatuses])

    const handlerFocusDate = (e: React.FocusEvent<HTMLInputElement>) => {
        setDateField(e.currentTarget.name as TDateField)
        setDateSelected(e.currentTarget.value ? new Date(e.currentTarget.value) : new Date())
        setIsOpenModalCalendar(true)
        e.currentTarget.blur()
    }

    const handlerClickDate = (field: TDateField) => {
        setDateField(field)
        setDateSelected(values[field] ? new Date(values[field]!) : undefined)
        setIsOpenModalCalendar(true)
    }

    const handlerAddFormFieldItem = (field: TMultiField, value: number | string) => {
        const fieldValue = values[field] ?? [] as (typeof value)[]

        if (!fieldValue.includes(value)) {
            change(field, [...fieldValue, value] as (number[] | string[]))
        }
    }

    const handlerDeleteFormFieldItem = (field: TMultiField, value: number | string) => {
        const fieldValue = values[field] ?? [] as (typeof value)[]
        const newValue = fieldValue.reduce((acc, item) => {
            return item !== value ? [...acc, item] : acc
        }, [] as typeof fieldValue)

        /** Reset active store catalog goods */
        if (field === FieldName.store) {
            setActiveStoreId(0)
        }

        change(field, newValue as (number[] | string[]))
    }

    useEffect(() => {
        if (onChange) {
            onChange(values)
        }
    }, [values])

    useEffect(() => {
        if (dataStoreShowcase) {
            setGoods((prevState) => ({
                ...prevState,
                ...dataStoreShowcase.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {}),
            }))
            setGoodsSuggest(dataStoreShowcase.map((item) => ({ id: item.id, value: item.name })))
        }
    }, [dataStoreShowcase])

    useEffect(() => {
        if (dataCompanyVendorCodes) {
            setVendors((prevState) => ({
                ...prevState,
                ...dataCompanyVendorCodes.data.reduce((acc, item) => ({ ...acc, [item.id]: item.text }), {}),
            }))
            setVendorsSuggest(dataCompanyVendorCodes.data.map((item) => ({ id: getId(true), value: item.id })))
        }
    }, [dataCompanyVendorCodes])

    return (
        <>
            <form
                id={values[FieldName.formId]}
                className={cn(classes, { [styleForm.submitting]: isSubmitting })}
                onSubmit={onSubmit}
            >
                <div className={classesBody}>
                    <CardContainer gap="15px">
                        <Block classes={style.block}>
                            <div className={style.blockHeader}>
                                <div className={style.blockTitle}>
                                    {t('order')}
                                </div>
                                <div>
                                    <Button
                                        classes={style.blockAction}
                                        styleType="text"
                                        text={t('clear')}
                                        onClick={() => {
                                            batch(() => {
                                                change(FieldName.orderId, undefined)
                                                change(FieldName.orderDateFrom, undefined)
                                                change(FieldName.orderDateTo, undefined)
                                                change(FieldName.paymentDateFrom, undefined)
                                                change(FieldName.paymentDateTo, undefined)
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            {t('order_id')}
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {t('order_id')}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    <FormDataType[FieldName.orderId]>
                                    name={FieldName.orderId}
                                    render={({ input }) => (
                                        <Input
                                            {...input}
                                            styleType="defaultBusiness"
                                        />
                                    )}
                                />
                            </div>
                            <Tabs
                                classes={style.tabs}
                                classesItem={style.tabsItem}
                                classesText={style.tabsText}
                                items={[
                                    { id: 1, name: t('order_date_create') },
                                    { id: 2, name: t('order_date_payment') },
                                ]}
                                active={activeTabDateId}
                                onChange={(id) => setActiveTabDateId(id)}
                            />
                            {activeTabDateId === 1 && (
                                <div className={styleForm.group}>
                                    <div className={cn(styleForm.groupItem)}>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <div className={style.fieldTextBefore}>
                                                {t('date_from')}
                                            </div>
                                        </div>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <div className={style.fieldTextBefore}>
                                                {t('date_to')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn(styleForm.groupItem)}>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <Field
                                                <FormDataType[FieldName.orderDateFrom]>
                                                name={FieldName.orderDateFrom}
                                                render={({ input, meta }) => (
                                                    <Input
                                                        {...input}
                                                        styleType="defaultBusiness"
                                                        onFocus={handlerFocusDate}
                                                        onChange={() => {}}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <Field
                                                <FormDataType[FieldName.orderDateTo]>
                                                name={FieldName.orderDateTo}
                                                render={({ input, meta }) => (
                                                    <Input
                                                        {...input}
                                                        styleType="defaultBusiness"
                                                        onFocus={handlerFocusDate}
                                                        onChange={() => {}}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className={cn(styleForm.groupItem)}>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <Button
                                                classes={cn(styleForm.groupItem, style.fieldIconAction)}
                                                styleType="transparent"
                                                onClick={() => handlerClickDate(FieldName.orderDateFrom)}
                                            >
                                                <SvgResource
                                                    isImgTag={false}
                                                    classes={cn(style.fieldIcon, style.iconCalendar)}
                                                    resourceKey="ic_kicks_calendar2_svg"
                                                    width={20}
                                                    height={20}
                                                />
                                            </Button>
                                        </div>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <Button
                                                classes={cn(styleForm.groupItem, style.fieldIconAction)}
                                                styleType="transparent"
                                                onClick={() => handlerClickDate(FieldName.orderDateTo)}
                                            >
                                                <SvgResource
                                                    isImgTag={false}
                                                    classes={cn(style.fieldIcon, style.iconCalendar)}
                                                    resourceKey="ic_kicks_calendar2_svg"
                                                    width={20}
                                                    height={20}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTabDateId === 2 && (
                                <div className={styleForm.group}>
                                    <div className={cn(styleForm.groupItem)}>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <div className={style.fieldTextBefore}>
                                                {t('date_from')}
                                            </div>
                                        </div>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <div className={style.fieldTextBefore}>
                                                {t('date_to')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn(styleForm.groupItem)}>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <Field
                                                <FormDataType[FieldName.paymentDateFrom]>
                                                name={FieldName.paymentDateFrom}
                                                render={({ input, meta }) => (
                                                    <Input
                                                        {...input}
                                                        styleType="defaultBusiness"
                                                        onFocus={handlerFocusDate}
                                                        onChange={() => {}}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <Field
                                                <FormDataType[FieldName.paymentDateTo]>
                                                name={FieldName.paymentDateTo}
                                                render={({ input, meta }) => (
                                                    <Input
                                                        {...input}
                                                        styleType="defaultBusiness"
                                                        onFocus={handlerFocusDate}
                                                        onChange={() => {}}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className={cn(styleForm.groupItem)}>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <Button
                                                classes={cn(styleForm.groupItem, style.fieldIconAction)}
                                                styleType="transparent"
                                                onClick={() => handlerClickDate(FieldName.paymentDateFrom)}
                                            >
                                                <SvgResource
                                                    isImgTag={false}
                                                    classes={cn(style.fieldIcon, style.iconCalendar)}
                                                    resourceKey="ic_kicks_calendar2_svg"
                                                    width={20}
                                                    height={20}
                                                />
                                            </Button>
                                        </div>
                                        <div className={cn(styleForm.row, styleForm.row_20, style.groupItemRow)}>
                                            <Button
                                                classes={cn(styleForm.groupItem, style.fieldIconAction)}
                                                styleType="transparent"
                                                onClick={() => handlerClickDate(FieldName.paymentDateTo)}
                                            >
                                                <SvgResource
                                                    isImgTag={false}
                                                    classes={cn(style.fieldIcon, style.iconCalendar)}
                                                    resourceKey="ic_kicks_calendar2_svg"
                                                    width={20}
                                                    height={20}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Block>
                        <Block classes={style.block}>
                            <div className={style.blockHeader}>
                                <div className={style.blockTitle}>
                                    {t('order_recipient')}
                                </div>
                                <div>
                                    <Button
                                        classes={style.blockAction}
                                        styleType="text"
                                        text={t('clear')}
                                        onClick={() => {
                                            batch(() => {
                                                change(FieldName.userName, undefined)
                                                change(FieldName.userCode, undefined)
                                                change(FieldName.userPhoneEmail, undefined)
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {t('order_recipient_name')}
                                </div>
                                <Field
                                    <FormDataType[FieldName.userName]>
                                    name={FieldName.userName}
                                    render={({ input }) => (
                                        <Input
                                            {...input}
                                            styleType="defaultBusiness"
                                        />
                                    )}
                                />
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            {t('order_recipient_code')}
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {t('order_recipient_code')}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    <FormDataType[FieldName.userCode]>
                                    name={FieldName.userCode}
                                    render={({ input }) => (
                                        <Input
                                            {...input}
                                            styleType="defaultBusiness"
                                        />
                                    )}
                                />
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {t('order_recipient_contacts')}
                                </div>
                                <Field
                                    <FormDataType[FieldName.userPhoneEmail]>
                                    name={FieldName.userPhoneEmail}
                                    render={({ input }) => (
                                        <Input
                                            {...input}
                                            styleType="defaultBusiness"
                                        />
                                    )}
                                />
                            </div>
                        </Block>
                        <Block classes={style.block}>
                            <div className={style.blockHeader}>
                                <div className={style.blockTitle}>
                                    {t('stores')}
                                </div>
                                <div>
                                    <Button
                                        classes={style.blockAction}
                                        styleType="text"
                                        text={t('clear')}
                                        onClick={() => {
                                            batch(() => {
                                                change(FieldName.country, undefined)
                                                change(FieldName.store, undefined)
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {t('country')}
                                </div>
                                <Field
                                    <FormDataType[FieldName.country]>
                                    name={FieldName.country}
                                    render={({ input }) => (
                                        <CountryDataSelect
                                            isCanEmpty
                                            isShowSelectArrow
                                            classesField={style.fieldSelect}
                                            countries={countries}
                                            selected={input.value ? countriesData[input.value] : undefined}
                                            selectedIsIcon={false}
                                            onChange={(value) => {
                                                return input.onChange(value.id)
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            {t('stores')}
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {t('stores')}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    <FormDataType[FieldName.store]>
                                    name={FieldName.store}
                                    render={({ input }) => (
                                        <CustomSelect.Select>
                                            <CustomSelect.Field
                                                classes={style.fieldSelect}
                                            >
                                                {/* */}
                                            </CustomSelect.Field>
                                            <CustomSelect.Options>
                                                {stores?.map((item) => (
                                                    <CustomSelect.Option
                                                        id={item.id}
                                                        isActive={input.value && input.value.includes(item.id)}
                                                        key={item.id}
                                                        onClick={() => {
                                                            setActiveStoreId(item.id)
                                                            input.onChange([...(input.value ?? []), item.id])
                                                        }}
                                                    >
                                                        {item.name}
                                                    </CustomSelect.Option>
                                                ))}
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                    )}
                                />
                                {values[FieldName.store] && (
                                    <div className={style.tags}>
                                        {values[FieldName.store].map((storeId, index) => (
                                            index < TAGS_MAX_SHOW && (
                                                <Tag
                                                    classes={style.tag}
                                                    classesContent={style.tagContent}
                                                    classesCross={style.tagCross}
                                                    key={storeId}
                                                    onClick={() => setActiveStoreId(storeId)}
                                                    onDelete={() => {
                                                        handlerDeleteFormFieldItem(FieldName.store, storeId)
                                                    }}
                                                >
                                                    {storesData[storeId]?.name}
                                                </Tag>
                                            )
                                        ))}
                                        {values[FieldName.store].length > TAGS_MAX_SHOW && (
                                            <div className={style.tagsCount}>
                                                {`+${values[FieldName.store].length - TAGS_MAX_SHOW}`}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Block>
                        <Block classes={style.block}>
                            <div className={style.blockHeader}>
                                <div className={style.blockTitle}>
                                    {t('order_product')}
                                </div>
                                <div>
                                    <Button
                                        classes={style.blockAction}
                                        styleType="text"
                                        text={t('clear')}
                                        onClick={() => {
                                            batch(() => {
                                                setGoodsSuggestId(getId(false))
                                                setVendorsSuggestId(getId(false))
                                                change(FieldName.goods, undefined)
                                                change(FieldName.vendor, undefined)
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            {t('order_goods')}
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {t('order_goods')}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    <FormDataType[FieldName.goods]>
                                    name={FieldName.goods}
                                    render={() => (
                                        <Suggest
                                            isShowArrow
                                            id={goodsSuggestId}
                                            classesField={style.fieldSelect}
                                            items={goodsSuggest}
                                            onSelect={({ id }) => handlerAddFormFieldItem(FieldName.goods, id)}
                                        />
                                    )}
                                />
                                {values[FieldName.goods] && (
                                    <div className={style.tags}>
                                        {values[FieldName.goods].map((goodsId, index) => (
                                            index < TAGS_MAX_SHOW && (
                                                <Tag
                                                    classes={style.tag}
                                                    classesContent={style.tagContent}
                                                    classesCross={style.tagCross}
                                                    key={goodsId}
                                                    onDelete={() => {
                                                        handlerDeleteFormFieldItem(FieldName.goods, goodsId)
                                                    }}
                                                >
                                                    {goods[goodsId]}
                                                </Tag>
                                            )
                                        ))}
                                        {values[FieldName.goods].length > TAGS_MAX_SHOW && (
                                            <div className={style.tagsCount}>
                                                {`+${values[FieldName.goods].length - TAGS_MAX_SHOW}`}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            {t('order_vendors')}
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {t('order_vendors')}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    <FormDataType[FieldName.vendor]>
                                    name={FieldName.vendor}
                                    render={({ input }) => (
                                        <Suggest
                                            isShowArrow
                                            id={vendorsSuggestId}
                                            classesField={style.fieldSelect}
                                            items={vendorsSuggest}
                                            onSearch={(value) => setTextSearchVendorCodes(value)}
                                            onSelect={({ value }) => handlerAddFormFieldItem(FieldName.vendor, value)}
                                        />
                                    )}
                                />
                                {values[FieldName.vendor] && (
                                    <div className={style.tags}>
                                        {values[FieldName.vendor].map((vendorId, index) => (
                                            index < TAGS_MAX_SHOW && (
                                                <Tag
                                                    classes={style.tag}
                                                    classesContent={style.tagContent}
                                                    classesCross={style.tagCross}
                                                    key={vendorId}
                                                    onDelete={() => {
                                                        handlerDeleteFormFieldItem(FieldName.vendor, vendorId)
                                                    }}
                                                >
                                                    {vendors[vendorId]}
                                                </Tag>
                                            )
                                        ))}
                                        {values[FieldName.vendor].length > TAGS_MAX_SHOW && (
                                            <div className={style.tagsCount}>
                                                {`+${values[FieldName.vendor].length - TAGS_MAX_SHOW}`}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Block>
                        <Block classes={style.block}>
                            <div className={style.blockHeader}>
                                <div className={style.blockTitle}>
                                    {t('delivery')}
                                </div>
                                <div>
                                    <Button
                                        classes={style.blockAction}
                                        styleType="text"
                                        text={t('clear')}
                                        onClick={() => {
                                            batch(() => {
                                                change(FieldName.isDeliveryRequired, undefined)
                                                change(FieldName.deliveryStatus, undefined)
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignCenter)}>
                                    <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            {t('order_delivery_required')}
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Field
                                            <FormDataType[FieldName.isDeliveryRequired]>
                                            name={FieldName.isDeliveryRequired}
                                            render={({ input }) => (
                                                <Switch
                                                    classes={style.switch}
                                                    name={input.name}
                                                    checked={Boolean(input.value)}
                                                    onChange={() => input.onChange(!input.value)}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Not shown in the StartUp version */}
                            {/* <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            Куда доставляем
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    Куда доставляем
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    <FormDataType[FieldName.deliveryAddress]>
                                    name={FieldName.deliveryAddress}
                                    render={({ input, meta }) => (
                                        <CustomSelect.Select>
                                            <CustomSelect.Field classes={style.fieldSelect}>
                                                 //
                                            </CustomSelect.Field>
                                            <CustomSelect.Options>
                                                //
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                    )}
                                />
                            </div> */}
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            {t('order_delivery_statuses')}
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {t('order_delivery_statuses')}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    <FormDataType[FieldName.deliveryStatus]>
                                    name={FieldName.deliveryStatus}
                                    render={({ input, meta }) => (
                                        <CustomSelect.Select>
                                            <CustomSelect.Field classes={style.fieldSelect}>
                                                {/* */}
                                            </CustomSelect.Field>
                                            <CustomSelect.Options>
                                                {deliveryStatuses?.map((item) => (
                                                    <CustomSelect.Option
                                                        id={item.id}
                                                        isActive={input.value && input.value.includes(item.id)}
                                                        key={item.id}
                                                        onClick={() => {
                                                            input.onChange([...(input.value ?? []), item.id])
                                                        }}
                                                    >
                                                        {item.name}
                                                    </CustomSelect.Option>
                                                ))}
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                    )}
                                />
                                {values[FieldName.deliveryStatus] && (
                                    <div className={style.tags}>
                                        {values[FieldName.deliveryStatus].map((statusId) => (
                                            <Tag
                                                classes={style.tag}
                                                classesContent={style.tagContent}
                                                classesCross={style.tagCross}
                                                key={statusId}
                                                onDelete={() => {
                                                    handlerDeleteFormFieldItem(FieldName.deliveryStatus, statusId)
                                                }}
                                            >
                                                {deliveryStatusesData[statusId]}
                                            </Tag>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </Block>
                        <Block classes={style.block}>
                            <div className={style.blockHeader}>
                                <div className={style.blockTitle}>
                                    {t('order_payment')}
                                </div>
                                <div>
                                    <Button
                                        classes={style.blockAction}
                                        styleType="text"
                                        text={t('clear')}
                                        onClick={() => {
                                            batch(() => {
                                                change(FieldName.paymentStatus, undefined)
                                                change(FieldName.paymentMethods, undefined)
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            {t('order_payment_statuses')}
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {t('order_payment_statuses')}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    <FormDataType[FieldName.paymentStatus]>
                                    name={FieldName.paymentStatus}
                                    render={({ input }) => (
                                        <CustomSelect.Select>
                                            <CustomSelect.Field classes={style.fieldSelect}>
                                                {/* */}
                                            </CustomSelect.Field>
                                            <CustomSelect.Options>
                                                {paymentStatuses
                                                && Object.entries(paymentStatuses).map(([key, name]) => (
                                                    <CustomSelect.Option
                                                        id={Number(key)}
                                                        isActive={input.value && input.value.includes(Number(key))}
                                                        key={key}
                                                        onClick={() => {
                                                            input.onChange([...(input.value ?? []), Number(key)])
                                                        }}
                                                    >
                                                        {name}
                                                    </CustomSelect.Option>
                                                ))}
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                    )}
                                />
                                {values[FieldName.paymentStatus] && paymentStatuses && (
                                    <div className={style.tags}>
                                        {values[FieldName.paymentStatus].map((statusId) => (
                                            <Tag
                                                classes={style.tag}
                                                classesContent={style.tagContent}
                                                classesCross={style.tagCross}
                                                key={statusId}
                                                title={paymentStatuses[statusId]}
                                                onDelete={() => {
                                                    handlerDeleteFormFieldItem(FieldName.paymentStatus, statusId)
                                                }}
                                            >
                                                {paymentStatuses[statusId]}
                                            </Tag>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                            {t('order_payment_methods')}
                                        </div>
                                    </div>
                                    <div className={styleForm.groupItem}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {t('order_payment_methods')}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    <FormDataType[FieldName.paymentMethods]>
                                    name={FieldName.paymentMethods}
                                    render={({ input }) => (
                                        <CustomSelect.Select>
                                            <CustomSelect.Field classes={style.fieldSelect}>
                                                {/* */}
                                            </CustomSelect.Field>
                                            <CustomSelect.Options>
                                                {paymentMethods
                                                && Object.entries(paymentMethods).map(([key, name]) => (
                                                    <CustomSelect.Option
                                                        id={Number(key)}
                                                        isActive={input.value && input.value.includes(Number(key))}
                                                        key={key}
                                                        onClick={() => {
                                                            input.onChange([...(input.value ?? []), Number(key)])
                                                        }}
                                                    >
                                                        {name}
                                                    </CustomSelect.Option>
                                                ))}
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                    )}
                                />
                                {values[FieldName.paymentMethods] && paymentMethods && (
                                    <div className={style.tags}>
                                        {values[FieldName.paymentMethods].map((methodId) => (
                                            <Tag
                                                classes={style.tag}
                                                classesContent={style.tagContent}
                                                classesCross={style.tagCross}
                                                key={methodId}
                                                title={paymentMethods[methodId]}
                                                onDelete={() => {
                                                    handlerDeleteFormFieldItem(FieldName.paymentMethods, methodId)
                                                }}
                                            >
                                                {paymentMethods[methodId]}
                                            </Tag>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </Block>
                    </CardContainer>
                </div>
                <div className={classesFooter}>
                    <div className={style.controls}>
                        <Button
                            classes={style.control}
                            size="size44"
                            type="submit"
                            text={t('apply')}
                        />
                    </div>
                </div>
            </form>

            <Modal
                isOpen={isOpenModalCalendar}
                size="extraSmallCenter"
                onClose={() => setIsOpenModalCalendar(false)}
            >
                <Modal.Body classes={style.modalCalendarBody}>
                    <DatePicker
                        isSetTime={false}
                        date={dateCalendar}
                        dateSelected={dateSelected}
                        onChange={(value) => {
                            if (dateField) {
                                change(dateField, defaultDateFormat(value))
                            }
                            setIsOpenModalCalendar(false)
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CompanyOrdersFilterForm
