import React from 'react'
import { Form } from 'react-final-form'

import { TStockroomSuppliesProps } from 'services/MarketService'
import { FormDataType, FieldName } from 'forms/WarehouseSuppliesFilterForm/WarehouseSuppliesFilterForm'
import { useFetchPersonalStores } from 'containers/Market/hooks'
import { WarehouseSuppliesFilterForm } from 'forms'

type WarehouseFilterActionPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    initialValues?: FormDataType
    isLoading: boolean
    onSuccess: (value: Omit<TStockroomSuppliesProps, 'companyId'>) => void
}

const WarehouseSuppliesFilterAction: React.FC<WarehouseFilterActionPropType> = ({
    classes,
    classesBody,
    classesFooter,
    initialValues,
    isLoading,
    onSuccess,
}) => {
    const { data: dataPersonalStores } = useFetchPersonalStores()

    const handlerSubmit = ({
        [FieldName.formId]: formId, // exclude from params
        [FieldName.store]: store,
        [FieldName.goods]: goods,
    }: FormDataType) => {
        onSuccess({
            [FieldName.store]: store?.length ? store.join(',') : undefined,
            [FieldName.goods]: goods?.length ? goods.join(',') : undefined,
        })
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            render={({ handleSubmit }) => (
                <WarehouseSuppliesFilterForm
                    classes={classes}
                    classesBody={classesBody}
                    classesFooter={classesFooter}
                    isSubmitting={isLoading}
                    stores={dataPersonalStores}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default WarehouseSuppliesFilterAction
