import {
    UseQueryOptions,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TPersonalStoreHistoryProps,
    TPersonalStoreHistoryResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TPersonalStoreHistoryProps
export type TQueryFnOpts = UseQueryOptions<
    TPersonalStoreHistoryResponse,
    TRequestError<TFetchFnParams>,
    TPersonalStoreHistoryResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.personalStoreHistory

/**
 * Hook API personal store wallet changelog
 */
export default function useFetchPersonalStoreHistory(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchPersonalStoreHistory(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
