import {
    UseQueryOptions,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TRequestError,
    TPersonalStoresHistoryProps,
    TPersonalStoresHistoryResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TPersonalStoresHistoryProps
export type TQueryFnOpts = UseQueryOptions<
    TPersonalStoresHistoryResponse,
    TRequestError<TFetchFnParams>,
    TPersonalStoresHistoryResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.personalStoresHistory

/**
 * Hook API personal stores wallets changelog
 */
export default function useFetchPersonalStoresHistory(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchPersonalStoresHistory(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
