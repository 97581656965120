/* DIRECTORY */
export const FETCH_LANGUAGES: string = 'DIRECTORY.FETCH_LANGUAGES'
export const FETCH_COUNTRIES: string = 'DIRECTORY.FETCH_COUNTRIES'
export const FETCH_RESOURCES: string = 'DIRECTORY.FETCH_RESOURCES'
export const FETCH_TRANSLATIONS: string = 'DIRECTORY.FETCH_TRANSLATIONS'
export const SET_TRANSLATIONS: string = 'DIRECTORY.SET_TRANSLATIONS'
export const FETCH_CITIES: string = 'DIRECTORY.FETCH_CITIES'
export const FETCH_STICKERS: string = 'DIRECTORY.FETCH_STICKERS'
export const FETCH_CURRENCIES: string = 'DIRECTORY.FETCH_CURRENCIES'

/* COUNTRY */
export const SET_COUNTRY_IDENTIFY: string = 'SET_COUNTRY_IDENTIFY'

/* USER */
export const AUTHORIZE_USER: string = 'AUTHORIZE_USER'
export const FETCH_USER: string = 'FETCH_USER'
export const FETCH_USER_PROFILE: string = 'FETCH_USER_PROFILE'
export const UPDATE_USER: string = 'UPDATE_USER'
export const UPDATE_USER_PROFILE: string = 'UPDATE_USER_PROFILE'
export const LOGOUT_USER: string = 'LOGOUT_USER'
export const SET_GEOLOCATION: string = 'SET_GEOLOCATION'
export const FETCH_USER_TAGS: string = 'FETCH_USER_TAGS'
export const FETCH_COUNTS: string = 'FETCH_COUNTS'
export const FETCH_COUNT: string = 'FETCH_COUNT'
export const RESET_COUNT: string = 'RESET_COUNT'

/* COMMUNITY */
export const UNSUBSCRIBE_COMMUNITY: string = 'UNSUBSCRIBE_COMMUNITY'

/* COMPANY */
export const FETCH_COMPANY_USER: string = 'FETCH_COMPANY_USER'
export const ADD_COMPANY: string = 'ADD_COMPANY'
export const UPDATE_COMPANY: string = 'UPDATE_COMPANY'
export const FETCH_COMPANY_BUSINESS_ROLES: string = 'FETCH_COMPANY_BUSINESS_ROLES'

/* MARKET */
export const FETCH_ORDERS: string = 'FETCH_ORDERS'
export const UPDATE_ORDERS: string = 'UPDATE_ORDERS'
export const CLEAR_ORDERS: string = 'CLEAR_ORDERS'

/* STORE */
export const FETCH_STORE_PROFILE = 'FETCH_STORE_PROFILE'
export const FETCH_STORE_CATALOGS_LIST = 'FETCH_STORE_CATALOGS_LIST'
export const FETCH_STORE_ROOT_CATALOG = 'FETCH_STORE_ROOT_CATALOG'
export const FETCH_STORE_CATALOG = 'FETCH_STORE_CATALOG'
export const FETCH_STORE_POPULAR_GOODS = 'FETCH_STORE_POPULAR_GOODS'
export const FETCH_STORE_CPA_LINK: string = 'FETCH_STORE_CPA_LINK'
export const UPDATE_STORE_PROFILE = 'UPDATE_STORE_PROFILE'

/* SUPPORT */
export const FETCH_TICKETS: string = 'FETCH_TICKETS'
export const FETCH_TICKET: string = 'FETCH_TICKET'
export const ADD_TICKET: string = 'ADD_TICKET'

/* APP */
export const ADD_MODAL: string = 'ADD_MODAL'
export const REMOVE_MODAL: string = 'REMOVE_MODAL'
export const SET_CONFIG: string = 'SET_CONFIG'
export const SET_SETTINGS: string = 'SET_SETTINGS'
export const UPDATE_SETTINGS: string = 'UPDATE_SETTINGS'

export const SET_DARK_DOOR: string = 'SET_DARK_DOOR'

export const CLEAR_STATE: string = 'CLEAR_STATE'

export const ADD_MESSAGE: string = 'ADD_MESSAGE'
export const REMOVE_MESSAGE: string = 'REMOVE_MESSAGE'
export const REMOVE_MESSAGES: string = 'REMOVE_MESSAGES'

export const OPEN_DIALOG: string = 'OPEN_DIALOG'
export const CLOSE_DIALOG: string = 'CLOSE_DIALOG'

export const OPEN_NOTIFY: string = 'OPEN_NOTIFY'
export const CLOSE_NOTIFY: string = 'CLOSE_NOTIFY'
