import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    SetDataOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    Updater,
    useQueryClient,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TStoreServiceError,
    TStoreProfileProps,
    TStoreProfileResponse,
} from 'services/StoreService'
import { QueryCacheKeys } from 'enums'
import { StoreService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TStoreProfileProps
export type TQueryFnOpts = UseQueryOptions<
    TStoreProfileResponse,
    [string, AxiosError<TStoreServiceError, TFetchFnParams>],
    TStoreProfileResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.storeProfile

/**
 * Hook API store profile
 */
export default function useFetchStoreProfile(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return StoreService.fetchStoreProfile(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

/**
 * Operations with query cached data
 */
export function useQueriesDataStoreProfile() {
    const user = useAppSelector((state) => state.user)
    const queryClient = useQueryClient()
    const queryKey = `${key}-${user.id}`

    /**
     * Update cached data of multiple queries
     */
    const setQueryData = (
        params: TFetchFnParams,
        updater: Updater<TStoreProfileResponse | undefined, TStoreProfileResponse | undefined>,
        options?: SetDataOptions,
    ) => {
        return queryClient.setQueriesData([queryKey, params], updater, options)
    }

    const remove = (params: TFetchFnParams, { exact = true, ...filters }: InvalidateQueryFilters = {}) => {
        return queryClient.removeQueries([queryKey, params], { exact, ...filters })
    }

    const invalidate = (
        params: TFetchFnParams,
        { exact = true, ...filters }: InvalidateQueryFilters = {},
        options?: InvalidateOptions,
    ) => {
        return queryClient.invalidateQueries([queryKey, params], { exact, ...filters }, options)
    }

    return {
        setQueryData,
        remove,
        invalidate,
    }
}
