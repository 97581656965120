import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    SetDataOptions,
    useQueryClient,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { IPersonalStore } from 'interfaces'
import {
    TMarketServiceError,
    TPersonalStoresResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TQueryFnOpts = UseQueryOptions<
    TPersonalStoresResponse,
    [string, AxiosError<TMarketServiceError, never>],
    TPersonalStoresResponse,
    [string]
>

const key = QueryCacheKeys.personalStores

/**
 * Хук API получить магазины компании
 */
export default function useFetchPersonalStores(opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`], () => {
        return MarketService.fetchPersonalStores()
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

/**
 * Operations with query cached data personal stores
 */
export function useQueriesDataPersonalStores() {
    const user = useAppSelector((state) => state.user)
    const queryClient = useQueryClient()
    const queryKey = `${key}-${user.id}`

    /**
     * Add to cache new item
     */
    const add = (data: IPersonalStore, options?: SetDataOptions) => {
        return queryClient.setQueryData<TPersonalStoresResponse>([queryKey], (queryData) => {
            return queryData ? [...queryData, data] : [data]
        }, options)
    }

    /**
     * Update item in cache
     */
    const update = (data: IPersonalStore, options?: SetDataOptions) => {
        return queryClient.setQueryData<TPersonalStoresResponse>([queryKey], (queryData) => {
            if (queryData) {
                return queryData.map((item) => {
                    return item.id === data.id ? { ...item, ...data } : item
                })
            }

            return queryData
        }, options)
    }

    const invalidate = ({ exact = true, ...filters }: InvalidateQueryFilters = {}, options?: InvalidateOptions) => {
        return queryClient.invalidateQueries([queryKey], { exact, ...filters }, options)
    }

    return {
        add,
        update,
        invalidate,
    }
}
