import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPersonalStoreBalance, ICurrency } from 'interfaces'
import { TPayoutHistoryProps } from 'services/PayoutService'
import { APP_URL, BREAKPOINTS } from 'config/app'
import {
    ContentContainer,
    SideBarContainer,
    ContentBackgroundBottom,
    Block,
} from 'layout'
import {
    PageHeader,
    Loader,
    NoDataInfo,
    Modal,
    ErrorMsg,
} from 'components'
import { useFetchPersonalStoresBalance, useFetchPersonalStoreHistory } from 'containers/Market/hooks'
import { useWindowResize } from 'hooks'
import { getCountFractionDigits, priceFormat } from 'utils/helpers'
import {
    WalletTransactionsFilter,
    WalletTransactions,
    WalletItemTotal,
    WalletItemPersonalStore,
} from '../components'
import { WalletStoreInfo, WalletStoreTransferDetails } from './components'
import style from './WalletStore.module.css'

const WalletStore: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const [walletId, setWalletId] = useState(Number.isInteger(Number(id)) ? Number(id) : undefined)
    const [transactionsFilterProps, setTransactionsFilterProps] = useState<Pick<TPayoutHistoryProps, 'dateFrom' | 'dateTo'>>()
    /** Current store currency */
    const [storeCurrency, setStoreCurrency] = useState<Partial<ICurrency>>()
    const [isOpenModalPayment, setIsOpenModalPayment] = useState(false)

    const {
        data: dataPersonalStoresBalance,
    } = useFetchPersonalStoresBalance({
        /** For get total funds in current store currency */
        converted_balance_currency_id: storeCurrency?.id,
    }, {
        enabled: !!walletId,
        keepPreviousData: true,
    })

    const {
        isInitialLoading: isLoadingPersonalStoreHistory,
        isFetching: isFetchingPersonalStoreHistory,
        data: dataPersonalStoreHistory,
        error: errorPersonalStoreHistory,
    } = useFetchPersonalStoreHistory({
        id: walletId ?? 0,
        ...transactionsFilterProps,
    }, {
        enabled: !!walletId,
    })

    const storeBalance = useMemo(() => {
        return dataPersonalStoresBalance
            ? dataPersonalStoresBalance.find((item) => item.id === walletId)
            : undefined
    }, [dataPersonalStoresBalance, walletId])

    const pageTitle = useMemo(() => {
        return storeBalance
            ? `${t('wallet_top_up')} "${storeBalance.name}"`
            : t('wallet_top_up')
    }, [storeBalance])

    const totalSumCurrency = useMemo(() => {
        const defaultFractionDigits = 2

        if (dataPersonalStoresBalance && storeBalance?.balances?.length) {
            const [balance] = storeBalance.balances
            const fractionDigits = getCountFractionDigits(balance.roundscale, defaultFractionDigits)
            const sum = dataPersonalStoresBalance.reduce((acc, item) => {
                return item.convertedBalanceAmount ? acc + item.convertedBalanceAmount : acc
            }, 0)

            return priceFormat(sum, {
                symbol: balance.currencyShowCode,
                isoCode: balance.currencyIsoCode,
                leftSymbolPlacement: false,
            }, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            })
        }

        return priceFormat(0, undefined, {
            minimumFractionDigits: defaultFractionDigits,
            maximumFractionDigits: defaultFractionDigits,
        })
    }, [dataPersonalStoresBalance, storeBalance])

    const handlerClickBack = () => {
        history.push(APP_URL.wallet)
    }

    const handlerChangeTransactionsFilter = (values: Pick<TPayoutHistoryProps, 'dateFrom' | 'dateTo'>) => {
        setTransactionsFilterProps(values)
    }

    const handlerClickWalletItemTotal = () => {
        history.push(APP_URL.wallet)
    }

    const handlerClickWalletItemPersonalStore = (value: IPersonalStoreBalance) => {
        history.push(generatePath(APP_URL.walletStore, { id: value.id }))
    }

    useEffect(() => {
        if (Number.isInteger(Number(id))) {
            setWalletId(Number(id))
        }
    }, [id])

    useEffect(() => {
        if (storeBalance?.balances?.length) {
            const [balance] = storeBalance.balances

            setStoreCurrency({
                id: balance.currencyId,
                showCode: balance.currencyShowCode,
                roundScale: balance.roundscale,
            })
        }
    }, [storeBalance])

    return (
        <>
            <ContentContainer size="half">
                <PageHeader
                    title={pageTitle}
                    onClickBack={handlerClickBack}
                />

                <WalletStoreInfo
                    data={storeBalance}
                    onClickPayment={() => setIsOpenModalPayment(true)}
                />

                {windowWidth < BREAKPOINTS.desktop && (
                    <div className={style.mobileBar}>
                        <div className={style.payoutsMobile}>
                            {dataPersonalStoresBalance?.map((item) => (
                                item.id !== walletId && (
                                    <WalletItemPersonalStore
                                        classes={style.listItem}
                                        data={item}
                                        convertedCurrency={storeCurrency}
                                        key={item.id}
                                        onClick={handlerClickWalletItemPersonalStore}
                                    />
                                )
                            ))}
                        </div>
                    </div>
                )}

                <div className={style.contentInner}>
                    <ContentBackgroundBottom isWidthGutter />

                    {isLoadingPersonalStoreHistory && (
                        <Loader />
                    )}

                    {!walletId && (
                        <NoDataInfo text={t('nothing_found')} />
                    )}

                    {errorPersonalStoreHistory && (
                        <ErrorMsg error={errorPersonalStoreHistory[0]} />
                    )}

                    {dataPersonalStoreHistory && (
                        <Block classes={style.block}>
                            <Block.Header>
                                <div className={style.blockTitle}>
                                    {t('wallet_total_income')}
                                </div>
                            </Block.Header>
                            <WalletTransactionsFilter
                                classes={cn(style.filter, { [style.filter_updating]: isFetchingPersonalStoreHistory })}
                                onChange={handlerChangeTransactionsFilter}
                            />

                            {dataPersonalStoreHistory && !dataPersonalStoreHistory.data?.length && (
                                <NoDataInfo text={t('nothing_found')} />
                            )}

                            <WalletTransactions
                                classes={style.transactions}
                                data={dataPersonalStoreHistory?.data}
                            />
                        </Block>
                    )}
                </div>
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    <div className={style.list}>
                        <WalletItemTotal
                            classes={style.listItem}
                            amount={totalSumCurrency}
                            onClick={handlerClickWalletItemTotal}
                        />

                        {dataPersonalStoresBalance?.map((item) => (
                            item.id !== walletId && (
                                <WalletItemPersonalStore
                                    classes={style.listItem}
                                    data={item}
                                    convertedCurrency={storeCurrency}
                                    key={item.id}
                                    onClick={handlerClickWalletItemPersonalStore}
                                />
                            )
                        ))}
                    </div>
                </SideBarContainer>
            )}

            <Modal
                isOpen={isOpenModalPayment}
                size="small2"
                onClose={() => setIsOpenModalPayment(false)}
            >
                <Modal.Header
                    isCloseButton
                    classes={style.modalHeader}
                    title={t('wallet_payment_bank_transfer')}
                    titlePos="left"
                />
                <Modal.Body>
                    <WalletStoreTransferDetails />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WalletStore
