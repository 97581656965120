import React, { useEffect, useState } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IPersonalStore } from 'interfaces'
import { TSetStoreLoyaltySettingsMutationFnError } from 'containers/Store/hooks/useStoreLoyaltySettings'
import { APP_URL } from 'config/app'
import { ContentContainer, Block } from 'layout'
import { useFetchPersonalStores, useQueriesDataPersonalStores } from 'containers/Market/hooks'
import { PageTitle, Loader, ErrorMsg } from 'components'
import { CompanyStoreSettingsAction } from 'form-actions'
import { scrollTop, showAlertNotify } from 'utils/helpers'
import style from './StoreSettings.module.css'

type StoreSettingsPropType = {}

const StoreSettings: React.FC<StoreSettingsPropType> = () => {
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const { t } = useTranslation()

    const {
        isInitialLoading: isLoading,
        data: dataPersonalStores,
        error: errorPersonalStores,
    } = useFetchPersonalStores()

    const [store, setStore] = useState<IPersonalStore>()

    const { update: updatePersonalStores } = useQueriesDataPersonalStores()

    const handlerSuccessStoreSettings = () => {
        if (store) {
            updatePersonalStores({ ...store, has_kick_loyalty_settings: true })
            history.push(generatePath(APP_URL.shop, { id: store.id }))
        }
    }

    const handlerErrorStoreSettings = (err: TSetStoreLoyaltySettingsMutationFnError) => {
        showAlertNotify({ type: 'error', message: err[0] })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataPersonalStores) {
            setStore(dataPersonalStores.find((item) => item.id === Number(id)))
        }
    }, [dataPersonalStores])

    return (
        <ContentContainer classes={style.content} size="half">
            <PageTitle classes={style.pageTitle} title={store?.name} />

            {isLoading && (
                <Loader />
            )}

            {!isLoading && !store && (
                <ErrorMsg error={t('nothing_found')} />
            )}

            {!isLoading && errorPersonalStores && (
                <ErrorMsg error={errorPersonalStores[0]} />
            )}

            {store && (
                <Block classes={style.block}>
                    <CompanyStoreSettingsAction
                        store={store}
                        onSuccess={handlerSuccessStoreSettings}
                        onError={handlerErrorStoreSettings}
                    />
                </Block>
            )}
        </ContentContainer>
    )
}

export default StoreSettings
